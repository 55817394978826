import request from '../request';

const serverName = '/iot-kcloud/kolb';

// 获取当前设备列表
export function getDeviceList(data) {
  return request(`${serverName}/equipment/list`, {
    method: 'GET',
    body: data,
  });
}

// 更新当前设备列表
export function updateKey(data) {
  return request(`${serverName}/equipment/equipmentKey`, {
    method: 'PUT',
    body: data,
  });
}

// 获取当前层数列表
export function getLayerList(id) {
  return request(`${serverName}/equipment/listUnit/${id}`, {
    method: 'GET',
  });
}

// 获取产品分类
export function getProcuctClassfy() {
  return request(`${serverName}/equipment/allProductType`, {
    method: 'GET',
  });
}

// 获取设备层数
export function getPDeviceLayers() {
  return request(`${serverName}/equipment/allDeviceLayers`, {
    method: 'GET',
  });
}

// 获取软件版本
export function getSoftwareVersionList() {
  return request(`${serverName}/equipment/allSoftwareVersion`, {
    method: 'GET',
  });
}
// 获取设备详情
export function getDeviceDetail(id) {
  return request(`${serverName}/equipment/detail/${id}`, {
    method: 'GET',
  });
}
// 解绑设备
export function unbindDevice(data) {
  return request(`${serverName}/equipment/unbind`, {
    method: 'PUT',
    body: data,
  });
}

// 层数详情状态修改
export function undateLayerStatus(data) {
  return request(`${serverName}/equipment/unit/status`, {
    method: 'PUT',
    body: data,
  });
}
// 设备列表获取软件版本统计信息
export function getVersionList(data) {
  return request(`${serverName}/equipment/softwareStatistics`, {
    method: 'POST',
    body: data,
  });
}

// 批量更新
export function batchUpdateDevice(data) {
  return request(`${serverName}/equipment/bulkUpdate`, {
    method: 'POST',
    body: data,
  });
}

// 批量下载
export function batchDownload(data) {
  return request(`/oss/iot/oss/batch/base64`, {
    method: 'POST',
    body: data,
  });
}

// 批量下载
export function checkFileAndVersion(data) {
  return request(`${serverName}/equipment/checkFileVersion`, {
    method: 'GET',
    body: data,
  });
}

// 获取备份 列表
export function getBackup(data) {
  return request(`${serverName}/equipment/backupListByType`, {
    method: 'GET',
    body: data,
  });
}

// 修改备注
export function putRemark(data) {
  return request(`${serverName}/equipment/equipmentName`, {
    method: 'PUT',
    body: data,
  });
}

/** @name 通过软件版本id集合获取设备模板 */
export function getListSoftwarePkId(data) {
  return request(`${serverName}/template/listBySoftwarePkId`, {
    method: 'POST',
    body: data,
  });
}
/** @name 删除模板 */
export function deleteTemplate(id) {
  return request(`${serverName}/template/delete/${id}`, {
    method: 'DELETE',
  });
}
